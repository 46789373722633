.not-found-wrap {
  background-image: url("/assets/images/page-bg-bottom.png");
  background-position-y: bottom;
  background-size: cover;
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 80px 0;
  h1 {
    font-weight: 800;
    margin-bottom: 16px;
    line-height: 1;
  }
  .subheading {
    font-weight: 800;
  }
}
