.qty-field {
  max-width: 48px;
  height: 33px;
  padding-left: 5px;
}

.stock-text {
  color: #f2aa00;
  font-weight: bold;
}
.card-body .product-name {
  font-size: 1.4em;
}

.product-cart .thumb-image {
  padding: 20px;
  height: 260px;
  .card-image-top {
    height: 100%;
  }
  .card-image-top a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-image {
    max-height: 100%;
  }
}

@media (min-width: 1520px) {
  .col-large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media all and (max-width: 767px){
  .list-grid .list-item .item-title {
    white-space: normal!important;
    max-width: inherit;
  }
}

@media all and (max-width: 575px) {
  .product-cart .thumb-image {
    padding: 20px;
    //height: auto;
  }
  .w-sm-100 {
    width: 100%;
  }
}

//cart
.product-cart-image {
  max-width: 100px;
  max-height: 100px;
}