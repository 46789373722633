.avatar-sm {
    width: 36px;
    height: 36px;
}

.avatar-md {
    width: 54px;
    height: 54px;
}

.avatar-lg {
    width: 80px;
    height: 80px;
}
.avatar-xl{
    width: 150px;
    height: 150px;
}

.avatar-sm-table {
    width: 20px;
    height: 20px;
}

@media all and (min-width: 576px) {
    .avatar-lg-container {
        min-width: 80px;
        min-height: 80px;
        width: 80px;
        height: 80px;
        img {
            //max-width: inherit;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%, -50%);
            width: auto;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media all and (max-width: 575px) {
    .avatar-sm-container {
        width: 280px;
        height: 200px;
        max-width: 100%;
        img {
            //max-width: inherit;
            //height: auto!important;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%, -50%);
            width: auto;
            height: 100%;
            object-fit: contain;
        }
    }
}