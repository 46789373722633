.search-ui {
  //position: fixed;
  background: $background;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0.75rem 1.5rem 0 4.5rem;
  z-index: 99999999;
  display: none;
  &.open {
    display: block;
    @include slideInDown($duration: 0.3s, $delay: 0);
  }
  .search-header {
    .logo {
      height: 64px;
      width: auto;
    }
  }
  input.search-input {
    font-size: 30px;
    font-weight: 600;
    border: 0;
    margin-bottom: 15px;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: $gray-400;
    }
  }
  .search-title {
    margin-bottom: 1.25rem;
    span {
      font-weight: 600;
    }
  }
}

.search-results.list-horizontal {
  height: 350px;
  overflow: auto;
}
label.search-label {
  margin-bottom: 0!important;
  max-width: 155px;
}

@media (min-width: 1200px) and (min-height: 800px) {
  .search-results.list-horizontal {
    height: 65%;
  }
}

@media (max-width: 1024px) and (max-height: 768px) {
  .search-results.list-horizontal {
    height: 65%;
  }
}

@media (max-width: 767px) {
  .search-ui {
    padding: 1rem;
  }
}

@media (max-width: 576px) {
  label.search-label {
    max-width: inherit;
  }
}
