.table-sticky {
      position: fixed;
      z-index: 1;
}

.layout-sidebar-large .table-sticky {
      top: $topbar-height;
      @media (max-width: 700px) {
            position: static;
      }
}