@each $gcolor_name, $gcolor in $gradient-colors {
  $start-color: map-get($gcolor, start-color);
  $end-color: map-get($gcolor, end-color);

  .sidebar-#{$gcolor_name} {
    .sidebar-left {
      @include directional-gradient(-154deg, $start-color, $end-color);
    }
  }
  .#{$gcolor_name} {
    @include directional-gradient(-154deg, $start-color, $end-color);
  }
  .btn.#{$gcolor_name} {
    &:active,
    &.active {
      @include directional-gradient(-90deg, $start-color, $end-color);
    }
  }
}

@each $color_name, $color in $solid-colors {
    .sidebar-#{$color_name} {
        .sidebar-left {
            background: $color;
        }
    }

  .theme-#{$color_name} {
    //text
    .text-primary {
      color: $color!important;
    }

    //primary button
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle,
    .btn-primary,
    .btn-primary:hover,
    .btn-primary:active,
    .btn-primary:visited {
      background-color: $color;
      border-color: $color;
    }
    .btn-primary:hover {
      box-shadow: 0 8px 25px -8px $color;
      border-color: $color;
    }
    .btn-primary:not(:disabled):not(.disabled):focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary:focus.dropdown-toggle,
    .btn-primary:focus {
      box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    }


    .btn-secondary:not(:disabled):not(.disabled):focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary:focus.dropdown-toggle {
      box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    }

    .btn-secondary:not(:disabled):not(.disabled):focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary:focus.dropdown-toggle,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle,
    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:active,
    .btn-secondary:visited
    .btn-outline-secondary,
    .btn-outline-secondary:hover,
    .btn-outline-secondary:active,
    .btn-outline-secondary:visited {
      border-color: $color;
      color: $color!important;
    }
    .btn-secondary:hover,
    .btn-outline-secondary:hover{
      box-shadow: 0 8px 25px -8px $color;
      border-color: $color;
    }
    .btn-secondary:focus,
    .btn-outline-secondary:focus {
      box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    }

    .btn-raised-primary {
      background: $color!important;
    }

    //elements
    .bg-primary {
      background-color: $color!important;
    }
    .badge-primary {
      background-color: $color;
    }
    .customizer .handle {
      background-color: $color;
      &:hover {
        background-color: $color;
      }
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: $color!important;
    }
    .react-datepicker__close-icon::after {
      background-color: $color;
    }
    .react-datepicker__navigation-icon {
      top: 7px
    }
    .pagination .active a {
      background-color: $color;
    }
    .page-item.active .page-link {
      border-color: $color;
    }
    .page-link:focus {
      box-shadow: 0 0 0 0.2rem rgba($color, 0.25);
    }
    .carousel-root .carousel .thumbs-wrapper .thumb {
      &.selected,
      &:hover {
        border: 2px solid $color;
      }
    }

    .list-group-item.active {
      background-color: $color;
    }
    .checkbox-primary input:checked ~ .checkmark {
      background-color: $color!important;
    }

    //navigation

    .nav-item {
      &.active {
        color: $color!important;
        .triangle {
          border-color: transparent transparent $color transparent;
        }
        .nav-item-hold {
          color: $color!important;
        }
      }
      &.dropdown-sidemenu.open a:hover {
        color: $color;
      }
    }
    .card-header-tabs a {
      color: $gray-800;
    }

  }



    .#{$color_name} {
        background: $color;
    }
}

// 
$full-map: gen-color-full-opacity-map($colors);
/*
$full-map: (
  blue: (
    100: #f31312,
    .....,
    900: #f32332
  ),
  red: (
    100: #f31312,
    .....,
    900: #f32332
  )
)
*/

@each $colorName, $map in $full-map {
  @each $name, $value in $map {
    .#{$colorName}-#{$name} {
      background-color: $value;
    }
    .text-#{$colorName}-#{$name} {
        @if (lightness( $value ) > 40) {
          color: black;
        }
        @else {
          color: white;
        }
    }
  }
}
