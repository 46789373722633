.nav-tabs {
    border-bottom: 1px solid $primary;
}

.nav-tabs .nav-item .nav-link.active {
    border: 1px solid transparent;
    background: rgba(102, 51, 153, .1);
    border-color: $primary $primary $background;
}

.dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    right: 5px;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.nav-tabs {
    padding-left: 10px;
    border: 0;
    margin-right: -0.625rem;
    margin-bottom: 0 !important;
    margin-left: -0.625rem;
}
.tab .tab-content {
    padding: 0 20px 0 20px;
    border: 1px solid #dee2e6;
    //margin-top: 11px;
}
@media all and (max-width: 575px) {
    .tab .tab-content {
        padding: 0 10px 0 10px;
    }
}