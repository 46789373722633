.card-body .form-row > .col,
.card-body .form-row > [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1300px) {
  .col-large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

//select.form-control {
//  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #f8f9fa;
//  -webkit-appearance: none;
//  background-position-x: 98%;
//  background-size: 12px;
//}

.custom-select {
  background-color: #f8f9fa;
  color: $gray-800;
}
.input-group-text.btn-primary {
  border: 1px solid #ced4da;
  &:hover {
    border: 1px solid #14449c;
  }
}

.input-group {
  background-color: #f8f9fa;
}

.remove-image {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.edit-image {
  position: absolute;
  top: 10px;
  left: 10px;
}
.move-image {
  position: absolute;
  top: 10px;
  right: 10px;
}
.download-image {
  position: absolute;
  bottom: 10px;
  right: 10px;
}




.popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 90%;
  max-width: 768px;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.popup-container.large {
  position: absolute;
  top: 100px;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 90%;
  max-width: 991px;
  transform: translateX(-50%);
  background-color: #fff;
}


@media all and (min-width: 768px) {
  .postal-code {
    bottom: -26px;
  }
}

@media all and (max-width: 767px) {
  .popup-container {
    position: absolute;
    top: 100px;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 90%;
    max-width: 768px;
    transform: translateX(-50%);
    background-color: #fff;
  }
}

//search
@media all and (max-width: 576px) {
  .input-group-append {
    margin-left: 0;
    button {
      border-bottom-left-radius: 4px!important;
      border-top-left-radius: 4px!important;
    }
  }
}