.ql-editor {
  min-height: 150px;
}

.ql-container {
  background-color: #f8f9fa;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}